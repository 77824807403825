import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../../components/seo';
import ConfiguratorHome from '../../screens/configurator';

import trads from '../../screens/configurator/trads';
// import ReinsuranceBanner from '../../components/eshop/ReinsuranceBanner';

import { getBrands } from './utils';
import { useLocale } from '../../services/i18n/useLocale';

const ConfiguratorHomeScreen = (props) => {
  const { data, pageContext } = props;
  const intl = useLocale();
  const brands = getBrands(data.brands.nodes);

  const title = intl.formatMessage(trads.configuratorTitle, {
    lineBreak: null,
  });

  return (
    <>
      <Seo
        title={intl.formatMessage(trads.configuratorTitle, {
          lineBreak: '',
        })}
      />
      <ConfiguratorHome
        brands={brands}
        title={title}
        rootPath={pageContext.rootPath}
      />
      {/* <ReinsuranceBanner
        border
      /> */}
    </>
  );
};

export const query = graphql`
  query ConfiguratorHome {
    brands: allConfiguratorBrandsJson {
      nodes {
        id: jsonId
        name
      }
    }
  }
`;

export default ConfiguratorHomeScreen;
